<template>
  <div class="flexdc flex1">
    <div>
      <div style="display: flex; height: 100%">
        <div style="flex: 1; width: 60%">
          <div class="operationControl">
            <div class="searchbox" style="padding-left: 0">
              <div title="课程名称" class="searchboxItem ci-full">
                <span class="itemLabel">课程名称:</span>
                <el-input
                  v-model="coursrName"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入课程名称"
                />
              </div>
              <span title="培训类型" class="searchboxItem ci-full flexcc">
                <span class="itemLabel" style="width: 95px">培训类型:</span>
                <tree
                  ref="tree"
                  @eventBtn="childBack"
                  :ruleForm="ruleForm"
                  size="small"
                  @clearParams="clearParams"
                  modal
                  typeStu
                />
              </span>
              <div>
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询</el-button
                >
              </div>
            </div>
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="课程名称"
              align="left"
              prop="courseName"
              width="250"
              fixed
            />
            <el-table-column
              label="总课时"
              align="center"
              show-overflow-tooltip
              prop="classHour"
            />
            <el-table-column
              label="总学时"
              align="center"
              show-overflow-tooltip
              prop="lessonNum"
            />
            <el-table-column
              label="课件数量"
              align="center"
              show-overflow-tooltip
              prop="kpointTotalNum"
            />
            <el-table-column
              label="课程总时长"
              align="center"
              show-overflow-tooltip
              width="180"
              prop="kpointTotalDuration"
            >
              <template slot-scope="scoped">
                {{ getTime(scoped.row.kpointTotalDuration) }}
              </template>
            </el-table-column>

            <el-table-column
              label="培训类型"
              align="center"
              show-overflow-tooltip
              prop="trainTypeNamePath"
              width="140"
            />
            <el-table-column
              label="行业类型"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.industryNamePath || "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="岗位类型"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.postName || "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="职业/工种"
              align="center"
              show-overflow-tooltip
              width="200"
            >
              <template slot-scope="scope">
                {{ scope.row.occupationNamePath || "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="培训等级"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.trainLevelName || "--" }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              show-overflow-tooltip
              prop="trainLevelName"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  style="padding: 0 5px"
                  @click="buy(scope.row.courseId, scope.row.mineCourseId)"
                  >使用
                </el-button>
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
        </div>
        <div style="display: flex; flex-direction: column; flex: 1; width: 40%">
          <div
            style="flex: 1; display: flex; flex-direction: column"
            class="ovy-a"
          >
            <div v-if="!rowId" class="flex1 flexdcc">
              <img
                src="../../../assets/recomEmpty.png"
                alt
                class
                style="width: 12rem"
              />
              <span>请在左侧选择您想要配置的课程</span>
            </div>
            <div v-else style="height: 10px">
              <h3 style="padding-bottom: 20px">总学时设置</h3>
              <div class="lessonNum">
                <span>所选课件总学时：{{ lessonNum || 0 }}学时</span>
                <span>必修课总学时：{{ lessonNummust }}学时</span>
                <span
                  >选修课总学时：{{
                    (lessonNum - lessonNummust).toFixed(1) || 0
                  }}学时</span
                >
              </div>
              <div style="display: flex">
                <p class="lessNumcount">
                  <span>要求完成总学时：</span>
                  <el-input-number
                    v-model="lessonNumDemand"
                    @change="handleChange"
                    size="small"
                    controls-position="right"
                    :min="0"
                  ></el-input-number>
                </p>
                <p style="display: flex; align-items: center">
                  <span>视频累计时长：{{ getTime(duration) }}</span>
                </p>
              </div>
              <div style="display:flex;padding-top: 10px;">
                <p class="lessNumcount">
                  <span>课时时长：</span>
                  <el-select
                    v-model="convertClassHour"
                    placeholder="请选择"
                    style="width: 130px;"
                    size="small"
                  >
                    <el-option
                      v-for="item in convertClassHourList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </p>
                <p style="display: flex;align-items: center;">
                  <span>总课时数：{{ sectionLessonPeriod(duration) }}</span>
                </p>
              </div>
              <div v-if="trainTypeId == '16' && projectSource == '20'">
                <el-form
                  :model="ruleFormWork"
                  :rules="rulesWork"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item label="工种选择" prop="workType">
                    <el-select
                      v-model="ruleFormWork.workType"
                      placeholder="请选择工种"
                      style="width: 40%"
                    >
                      <el-option
                        v-for="item in typeofWorkList"
                        :key="item.sourceProjectCourseId"
                        :label="item.sourceIndustryName"
                        :value="item.sourceProjectCourseId"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>
              <h3 style="padding-bottom: 10px">章节编排</h3>
              <el-button class="bgc-bv" @click="selectChange" size="mini"
                >反选</el-button
              >
              <div style="height: 100%; display: flex; min-width: 1000px">
                <el-tree
                  ref="tree"
                  :data="data"
                  show-checkbox
                  node-key="courseKpointId"
                  default-expand-all
                  :default-checked-keys="this.selects"
                  :expand-on-click-node="false"
                  :props="defaultProps"
                  @check-change="getChecked"
                >
                  <span class="custom-tree-node" slot-scope="{ data }">
                    <template v-if="data.level == 1">
                      <span
                        v-text="data.catalogName"
                        class="ellipsis"
                        style="width: 27.2rem;"
                        :title="data.catalogName"
                      ></span>
                      <span style="margin-right: 5px;">{{ chapterLessonPeriod(data.courseKpointId) }}课时</span>
                      <span>{{ chapterDuration(data.courseKpointId) }}</span>
                    </template>
                    <template v-else-if="data.level == 2">
                      <span
                        v-if="data.list.length > 0"
                        style="
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                        "
                      >
                        <i class="courseIcon" v-if="data.list[0].kpointId"></i>
                        <i
                          class="paperIcon"
                          v-else-if="data.list[0].paperId"
                        ></i>
                      </span>
                      <span
                        v-text="data.catalogName"
                        class="ellipsis"
                        :title="data.catalogName"
                      ></span>
                      <span style="margin-right: 5px;" v-if="data.list[0].kpointId">{{ sectionLessonPeriod(data.kpointDuration) }}课时</span>
                      <span>{{ data.kpointDurationStr }}</span>
                      <el-button
                        type="text"
                        @click="showVideo(data)"
                        size="mini"
                        style="margin: 0 1rem"
                        >预览</el-button
                      >
                      <el-radio-group
                        v-model="data.requiredState"
                        class="set-food-select"
                        size="mini"
                        value-key="specId"
                        style="padding-right: 10px"
                        @change="change(data)"
                      >
                        <el-radio :label="true">必修课</el-radio>
                        <el-radio :label="false">选修课</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span
                        v-text="data.catalogName"
                        :title="data.catalogName"
                        class="ellipsiss"
                      ></span>
                      <el-button
                        type="text"
                        @click="showPaper(data)"
                        size="mini"
                        style="margin: 0 1rem"
                        >预览</el-button
                      >
                    </template>
                  </span>
                </el-tree>
              </div>
            </div>
          </div>
          <div
            style="display: flex; justify-content: center; padding-top: 17px"
            v-show="rowId"
          >
            <el-button @click="$router.back()" class="bgc-bv">取消</el-button>
            <el-button @click="getCheckedNodes()" class="bgc-bv"
              >确定</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <PaperShow ref="PaperShow" />
    <el-dialog
      v-if="dialogVisible1"
      :title="videotitle"
      :visible.sync="dialogVisible1"
      width="60%"
      :before-close="handleClose"
    >
      <player_2
        :videoId="videoId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-if="videoType"
      />
      <player_1
        :videoId="videoId"
        :videoTime="videoTime"
        :kpointSource="kpointSource"
        v-else
      />
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import PaperShow from "@/views/resourse/popup/PaperShow";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import player_1 from "@/components/player";
import player_2 from "@/components/playerTrysee";
import { createSwigger, distorySwigger } from "../../../utils/swigger";
export default {
  components: {
    Empty,
    PageNum,
    PaperShow,
    tree,
    player_1,
    player_2,
  },
  mixins: [List],
  data() {
    return {
      rowId: "",
      data: [],
      radio: "",
      coursrName: "",
      defaultProps: {
        id: "courseKpointId",
        children: "children",
        label: "courseKpointId",
      },
      projectId: "",
      lessonNum: 0,
      lessonNummust: 0,
      convertClassHour:'45',//课时时长默认45
      convertClassHourList:[],//课时时长字典
      lessonNumDemand: "",
      datatreeList: [],
      trainTypeId: "",
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      selects: [],
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      chapterSchema: true,
      stu: false,
      buyId: "",
      notifyBody: {},
      // projectCourseId:this.$route.params.projectCourseId
      weightSort: "2",
      videoId: "", // 視頻id
      videoType: null, // true-可以看；false-试看
      dialogVisible1: false,
      videotitle: "",
      videoTime: "",
      status: 1,
      duration: 0,
      durationStr: "",
      typeofWorkList: [],
      ruleFormWork: {
        workType: "",
      },
      rulesWork: {
        workType: [
          { required: true, message: "请选择工种", trigger: "change" },
        ],
      },
      // 我也不知道是啥 - 赵金福改于20220821-17:09改的
      cClessonNum: "",
      projectSource: "",
    };
  },
  props: {
    projectCourseId: {
      type: Number,
    },
  },
  computed:{
    // 节课时数换算
    sectionLessonPeriod() {
      return (num) => {
        let res = (num / (~~this.convertClassHour * 60))
        return  (parseInt(res * 100) / 100).toFixed(2)
      }
    },
    // 章课时数换算
    chapterLessonPeriod() {
      return (courseKpointId) => {
        const curList = this.$refs.tree.getCheckedNodes()
        const list = curList.filter(e=>e.parentNodeId == courseKpointId)
        if(list?.length>0){
          let total = 0
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            let res = (element.kpointDuration / (~~this.convertClassHour * 60))
            total+= +(parseInt(res * 100) / 100).toFixed(2)
          }
          return total.toFixed(2)
        }else{
          return '0'
        }
      }
    },
    // 章视频时长换算
    chapterDuration() {
      return (courseKpointId) => {
        const curList = this.$refs.tree.getCheckedNodes()
        const list = curList.filter(e=>e.parentNodeId == courseKpointId)
        if(list?.length>0){
          let total = 0
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            total+= element.kpointDuration
          }
          return this.getTime(total)
        }else{
          return '0'
        }
      }
    }
  },
  created() {
    this.cClessonNum = sessionStorage.getItem("cClessonNum");
    this.projectSource = sessionStorage.getItem("projectSource");
    this.projectSource = sessionStorage.getItem("projectSource");
    this.trainTypeId = sessionStorage.getItem("trainTypeId");
    if (this.projectSource == "20") {
      this.notifyBody = this.$notify({
        message: "该班级要求配课" + this.cClessonNum + "学时",
        duration: 0,
      });
    }
    this.getDictionary()
  },
  mounted() {},
  methods: {
    // 获取字典
    getDictionary() {
      // 课时时长
      let arr = this.$setDictionary("CONVERT_CLASS_HOUE", "list");
      for (const key in arr) {
        this.convertClassHourList.push({
          label: arr[key],
          value: key,
        });
      }
    },
    childBack(params) {
      this.params = { ...params };
      this.selectType = this.params.trainFormIdPath;
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: 10,
        pageSize: this.pageSize,
        resourceName: this.coursrName || "",
        projectId: sessionStorage.getItem("projectId"),
      };
      if (this.weightSort) {
        params.weightSort = this.weightSort;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/course/queryPlatformRecommendedCourse",
        params,
        pageNum,
      });
    },
    change(data) {
      let num = 0;
      this.$refs.tree.getCheckedNodes().map((item, index) => {
        if (item.level == 2) {
          if (item.kpointId) {
            if (item.requiredState == true) {
              if (item.kpointLessonNum) {
                num += item.kpointLessonNum;
              }
            }
          }
        }
      });
      this.lessonNummust = num.toFixed(1);
      this.lessonNumDemand = num.toFixed(1);
    },
    getChecked(data, checked, indeterminate) {
      let num = 0;
      let ElectiveNum = 0;
      let duration = 0;
      //勾选的
      this.$refs.tree.getCheckedNodes().map((item, index) => {
        if (item.level == 2) {
          if (item.kpointId) {
            if (item.requiredState == true) {
              num += item.kpointLessonNum;
            } else {
              if (item.kpointLessonNum) {
                ElectiveNum += item.kpointLessonNum;
              }
            }
            duration += item.kpointDuration;
          }
        }
      });
      this.lessonNum = (num + ElectiveNum).toFixed(1);
      this.lessonNummust = num.toFixed(1);
      this.lessonNumDemand = (num + ElectiveNum).toFixed(1);
      this.duration = duration;
    },
    showPaper(data) {
      this.$refs.PaperShow.showPopUp(data.paperId); // 打开弹窗预览试题
    },
    /**
     * 保存
     */
    getCheckedNodes() {
      if (this.projectSource == "20") {
        this.notifyBody.close();
      }
      let checked = [];
      checked = this.$refs.tree
        .getCheckedKeys()
        .concat(this.$refs.tree.getHalfCheckedKeys());
      if (this.lessonNumDemand == "") {
        this.$message({
          message: "请输入要求完成总学时",
          type: "warning",
        });
        return false;
      }
      if (this.lessonNumDemand <= 0) {
        this.$message({
          message: "要求完成总学时不能为0或小于0",
          type: "warning",
        });
        return false;
      }
      if (this.lessonNumDemand > this.lessonNum) {
        this.$message({
          message: "要求完成总学时不能大于所选课件总学时",
          type: "warning",
        });
        return false;
      }
      if (this.lessonNumDemand < this.lessonNummust) {
        this.$message({
          message: "要求完成总学时不能小于所选课件必修课总学时",
          type: "warning",
        });
        return false;
      }
      if (checked.length == 0) {
        this.$message({
          message: "请选择课程编排",
          type: "warning",
        });
        return false;
      }
      // 长春班级
      if (this.projectSource == "20") {
        if (
          this.lessonNumDemand != this.cClessonNum &&
          this.lessonNum != this.cClessonNum
        ) {
          this.$message({
            message:
              "所选课件总学时和要求完成总学时必须等于规定学时,(" +
              this.cClessonNum +
              "学时)",
            type: "warning",
          });
          return false;
        }
        if (this.trainTypeId == "16" && !this.ruleFormWork.workType) {
          this.$message({
            message: "请选择工种",
            type: "warning",
          });
          return false;
        }
      }

      if (this.lessonNummust.split(".")[1] > 0) {
        this.$message({
          message: "要求必修课总学时必须为整数",
          type: "warning",
        });
        return false;
      }
      let parmar = {
        courseId: this.rowId,
        lessonNum: this.lessonNum.substring(0, this.lessonNum.indexOf(".")),
        lessonNumDemand: this.lessonNumDemand,
        lessonNumMust: this.lessonNummust.substring(
          0,
          this.lessonNummust.indexOf(".")
        ),
        modify: 1,
        projectId: sessionStorage.getItem("projectId"),
        selectIds: checked,
        selectNode: this.data,
        buyId: this.buyId,
        sourceProjectCourseId: this.projectCourseId,
        ccProjectCourseId: this.ruleFormWork.workType || "",
        convertClassHour:this.convertClassHour,
      };
      this.$post(
        this.projectCourseId
          ? "/biz/projectCourse/replaceProjectCourse"
          : "/biz/projectCourseOutline/disposeProjectCourse",
        parmar
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.$notify.close();
            sessionStorage.setItem("refresh", 10);
            // this.$router.back()
            this.$router.push({
              path: "/web/ClassdetailsList",
              query: {
                active: "second",
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    handleClose() {
      (this.dialogVisible1 = false), (this.status = 3);
      distorySwigger();
    },
    // 预览
    showVideo(data) {
      let _this = this;
      //单元测试打开是题库
      if (data.list[0].kpointVideoId) {
        _this
          .$post("/biz/courseware/auth", {
            videoId: data.list[0].kpointVideoId,
          })
          .then((result) => {
            _this.videoId = data.list[0].kpointVideoId;
            // _this.videoType = result.data.trySee;
            _this.videoType = false;
            _this.videoTime = result.data.duration;
            _this.videotitle = data.catalogName;
            _this.kpointSource = data.list[0].kpointSource;
            _this.dialogVisible1 = true;
          });
      }
      if (!data.list[0].kpointVideoId && data.paperId) {
        this.showPaper(data);
      }
    },
    /**
     *获取 树结构
     */
    getDetail(courseId) {
      this.$post("/biz/projectCourseOutline/viewOutline", {
        courseId,
      }).then((result) => {
        if (result.status === "0") {
          this.creatData(result.data.tree);
          this.selects = result.data.allKey.map(Number);
          this.buyId = result.data.tree.buyId;
          this.lessonNum = result.data.tree.lessonNum.toFixed(1);
          this.lessonNummust = result.data.tree.lessonNum.toFixed(1);
          this.lessonNumDemand = result.data.tree.lessonNum.toFixed(1);
          this.duration = result.data.tree.duration;
          this.durationStr = result.data.tree.durationStr;
          this.convertClassHour = result.data.tree?.convertClassHour ?? '45';
        }
      });
    },
    creatData(data = {}) {
      let list = [];
      this.chapterSchema = data.chapterSchema;
      if (data.chapterSchema) {
        // 样式一 ------> 三层
        data.nodes.forEach((element) => {
          let nodeList = [];
          list.push({
            courseKpointId: element.courseKpointId,
            catalogName: element.catalogName,
            kpointDuration: element.kpointDuration,
            kpointDurationStr: element.kpointDurationStr,
            parentNodeId: element.parentNodeId,
            children: nodeList,
            level: 1,
          });
          (element.child || []).forEach((obj) => {
            nodeList.push(this.created2dom(obj));
          });
        });
      } else {
        data.nodes.forEach((element) => {
          list.push(this.created2dom(element));
        });
      }
      this.data = list;
    },
    created2dom(obj) {
      let children = [];
      if (obj.kpointId && obj.paperId) {
        children = [
          {
            catalogName: obj.paperName,
            courseKpointId: obj.courseKpointId,
            sourceCourseKpointId: obj.courseKpointId,
            level: 3,
            paperId: obj.paperId,
          },
        ];
      }
      let listArr = [];
      if (obj.kpointVideoId) {
        obj.list = listArr;
        listArr.push({
          kpointId: obj.kpointId,
          kpointVideoId: obj.kpointVideoId,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
          buyId: obj.buyId,
          kpointSource: obj.kpointSource,
        });
      } else if (obj.paperId) {
        obj.list = listArr;
        listArr.push({
          paperId: obj.paperId,
        });
      }
      //判断kpointId存在，paperId存在，带试卷的课件，kpointId不存在，paperId存在是单元测试
      if (obj.kpointId) {
        return {
          catalogName: obj.catalogName,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: children,
          requiredState: obj.requiredState,
          section: obj.section,
          courseKpointId: obj.courseKpointId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          buyId: obj.buyId,
        };
      } else {
        return {
          catalogName: obj.catalogName,
          kpointDuration: obj.kpointDuration,
          kpointDurationStr: obj.kpointDurationStr,
          parentNodeId: obj.parentNodeId,
          kpointLessonNum: obj.kpointLessonNum,
          level: 2,
          list: listArr,
          children: [],
          requiredState: false,
          section: obj.section,
          courseKpointId: obj.courseKpointId,
          sourceCourseId: this.courseId,
          sourceCourseKpointId: obj.courseKpointId,
          kpointId: obj.kpointId,
          buyId: obj.buyId,
          paperId: obj.paperId,
          paperName: obj.paperName,
        };
      }
    },
    /* 选购 */
    buy(courseId, mineCourseId) {
      let that = this;
      this.$post("/course/useset/selectListByType", {
        courseId: courseId,
      })
        .then((res) => {
          if (res.data && res.data.tipcontent) {
            this.$confirm(res.data.tipcontent, "提示")
              .then((_) => {
                jx();
              })
              .catch((_) => {});
          } else {
            jx();
          }
        })
        .catch(() => {
          return;
        });
      function jx(){
        if (mineCourseId) {
          that.rowId = mineCourseId;
          that
            .$post("/biz/projectCourseOutline/viewOutline", {
              courseId: mineCourseId,
            })
            .then((ret) => {
              that.getData(-1);
              if (that.rowId) {
                that.getDetail(mineCourseId);
                that.getTreeTrain();
                that.$nextTick(() => {
                  that.$refs.tree.setCheckedNodes(that.data);
                });
              }
            })
            .catch((err) => {
              return;
            });
        } else {
          that
            .$post("/biz/course/insertPlatformCourseToMine", { courseId })
            .then((ret) => {
              that.$message({
                message: "已成功加入我的课程",
                type: "success",
              });
              that.rowId = ret.data.addId;
              that.getData(-1);
              if (that.rowId) {
                that.getDetail(ret.data.addId);
                that.$nextTick(() => {
                  that.$refs.tree.setCheckedNodes(that.data);
                });
                that.$emit("getId", that.rowId);
              }
            })
            .catch((err) => {
              return;
            });
        }
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 0.675) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 32;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    //反选
    selectChange() {
      let res = this.$refs.tree;
      let nodes = res.getCheckedNodes(true, true);
      this.batchSelect(this.data, res, true, nodes);
    },
    batchSelect(nodes, refs, flag, selecteds) {
      if (typeof nodes != "undefined") {
        nodes.forEach((element) => {
          refs.setChecked(element, flag, true);
        });
      }
      if (typeof selecteds != "undefined") {
        selecteds.forEach((node) => {
          refs.setChecked(node, !flag, true);
        });
      }
    },
    getTreeTrain() {
      this.$post("/biz/sou/cc/querySourceProjectCourseList", {
        projectId: sessionStorage.getItem("projectId"),
        isAll: false,
        projectCourseId: this.projectCourseId,
      }).then((ret) => {
        this.typeofWorkList = ret.data;
        this.ruleFormWork.workType = ret.data[0].sourceProjectCourseId || "";
      });
    },
  },
};
</script>
<style lang="less">
.el-input-number__decrease {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
<style lang="less" scoped>
.el-tree {
  width: 100%;
}

.lessonNum {
  display: flex;
  padding: 1rem;
  span {
    padding: 0 1rem;
  }
}

.lessNumcount {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  span {
    width: 10rem;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-tree-node {
  display: flex;
  align-items: center;
  .el-form-item {
    margin-bottom: 0px;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 25rem;
  font-size: 14px;
}

.ellipsiss {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 23.85rem;
  font-size: 14px;
}

.el-table .warning-row {
  background: red;
}

.el-table .success-row {
  background: #ffffff;
}
.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}
</style>
