<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">班级列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">新增课程</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave" @tab-click="tabChange">
          <el-tab-pane label="推荐课程" name="first" >
            <addCourseCtr ref="first" :projectCourseId='projectCourseId' @getId="getId"/>
          </el-tab-pane>
          <el-tab-pane label="我的课程" name="second">
            <addMyCourse ref="second" :projectCourseId='projectCourseId' :courseId='courseId' />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import addMyCourse from "@/views/classListCom/classDetailsFile/addMyCourse.vue";
import addCourseCtr from "@/views/classListCom/classDetailsFile/addCourseCtr.vue";
export default {
  name: "addCourseBox",
  components: {
    addMyCourse,
    addCourseCtr
  },
  mixins: [List],
  data() {
    return {
      projectCourseId:this.$route.query.projectCourseId,
      activeName: "first",
      projectName: '',
      courseId:'',
    };
  },
  methods: {
    init() {
      this.projectName = sessionStorage.getItem("projectName");
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      } else {
        this.activeName = "first";
      }
    },
    getId(data) {
      this.courseId = data;
    },
    beforeTabLeave(newName) {
      this.$router.replace({path:this.$route.path, query:{active:newName}});
    },
    tabChange(e) {
      this.$refs[e.name].getData(-1);
      if(this.courseId) {
        this.$refs[e.name].getDetail(this.courseId);
        this.$refs[e.name].rowId = this.courseId;
      }
    }
  }
};
</script>
<style lang="less"></style>
